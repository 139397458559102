import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'FullScreenErrorPage';

export default defineMessages({
  somethingWentWrong: {
    id: `${I18N_NAMESPACE}.somethingWentWrong`,
    defaultMessage: 'Something went wrong!',
  },

  errorDescription: {
    id: `${I18N_NAMESPACE}.errorDescription`,
    defaultMessage:
      'Please try again later. If the problem cannot be resolved you can contact us via {chat}, or send an e-mail message to {email}.',
  },

  errorDescriptionWhenChatIsDisabled: {
    id: `${I18N_NAMESPACE}.errorDescriptionWhenChatIsDisabled`,
    defaultMessage:
      'Please try again later. If the problem is not solved, you can contact us at {email}.',
  },
});
