import { cn } from '../../lib/classNames';

type ErrorPageProps = {
  children: React.ReactNode;
};

const Layout = ({
  children,
  className,
}: ErrorPageProps & {
  className?: string;
}) => (
  <div className={cn('flex-grow px-8 pt-14 md:pt-28', className)}>
    <div className="relative mx-auto grid h-full max-w-4xl">{children}</div>
  </div>
);

const Heading = ({
  children,
  className,
}: ErrorPageProps & {
  className?: string;
}) => (
  <h1 className={cn('mb-2 text-3xl font-medium', className)}>{children}</h1>
);

const Illustration = ({ children }: ErrorPageProps) => (
  <div className="ml-auto inline w-full max-w-[75%] self-end md:max-w-lg">
    {children}
  </div>
);

const ErrorPage = {
  Layout,
  Heading,
  Illustration,
};

export default ErrorPage;
