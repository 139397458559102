import { useState } from 'react';
import { FormattedMessage as T } from 'react-intl';
import NotificationBanner from '../NotificationBanner/NotificationBanner';
import BannerContent from '../NotificationBanner/BannerContent';

import m from './messages';

const componentName = 'GlobalAlert';

const SanityBanner = () => {
  const [isDismissed, setDismissed] = useState(false);
  return (
    <NotificationBanner
      isDismissed={isDismissed}
      onDismiss={() => setDismissed(true)}
      type="error"
    >
      <BannerContent>
        <T {...m.content} />
      </BannerContent>
    </NotificationBanner>
  );
};

SanityBanner.displayName = componentName;

export default SanityBanner;
