import {
  resolveAffiliateTheme,
  resolveBusinessUnitTheme,
} from './cloudConfig/themes';
import { Cloud$BusinessUnitConfig } from './types/cloudApi';

function resolveTheme(buConfig: Cloud$BusinessUnitConfig) {
  const { matchedPartner } = buConfig;

  if (
    matchedPartner?.type === 'AFFILIATE' &&
    matchedPartner?.config.theming_enabled
  ) {
    const normalizedUtmSources = matchedPartner.config.utm_sources.map(
      (source) => source.toLowerCase(),
    );
    return resolveAffiliateTheme(normalizedUtmSources);
  }

  return resolveBusinessUnitTheme(buConfig.slug);
}

export default resolveTheme;
