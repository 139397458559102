export const openChat = () => {
  if (!__isBrowser__) {
    return;
  }

  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.open();
  }
};

/*
Refresh and re-render the widget's information, given the current page URL.

This method can be useful for refreshing the widget on route changes. This
allows you to specify different chatflows on different page routes. If
widget.refresh is called on a route where there is no chatflow, and the user
isn't engaged in a conversation, the widget will be removed.
*/
export const refreshWidget = (): void => {
  if (!__isBrowser__) {
    return;
  }

  if (!window.HubSpotConversations) {
    return;
  }

  const { widget } = window.HubSpotConversations;

  const status = widget.status();

  if (status.loaded) {
    widget.refresh();
  } else {
    widget.remove();
    /* setTimeout is used as a way to call widget.load() when the widget.remove() is resolved
    
     Better approach would be to use some kind of an event like "closed", but we don't get it from the
     chat widget sdk: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
    */
    setTimeout(() => {
      widget.load();
    }, 2000);
  }
};

export const isChatAvailable = (): boolean => {
  if (!__isBrowser__) {
    return false;
  }

  if (window.HubSpotConversations) {
    const { loaded } = window.HubSpotConversations.widget.status();
    return !!loaded;
  }
  return false;
};
