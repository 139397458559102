import { css, Global, ThemeProvider } from '@emotion/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { SWRConfig } from 'swr';
import GlobalAlert from './components/GlobalAlert/GlobalAlert';
import LanguageProvider from './components/LanguageProvider';
import { GLOBAL_ALERT } from './config/featureToggles/constants';
import { isEnabled } from './config/featureToggles/utils';
import { OtovoConfigContext } from './hooks/configContext';
import { setLocale } from './lib/dayjs';
import { RainbowTheme } from './rainbow/types';
import { Otovo$Config, Otovo$Locale } from './types/otovoweb';

type NextPageRootProps = {
  children: React.ReactNode;
  configValues: Otovo$Config;
  theme: RainbowTheme;
};

const NextPageRoot = ({ children, configValues, theme }: NextPageRootProps) => {
  const { locale } = useRouter() as { locale: Otovo$Locale };
  const {
    BU_CONFIG: { locale: defaultLocale },
  } = configValues;

  useEffect(() => {
    setLocale((locale as string) !== 'default' ? locale : defaultLocale);
  }, [locale, defaultLocale]);

  return (
    <OtovoConfigContext.Provider value={configValues}>
      <LanguageProvider config={configValues}>
        <>
          <Global
            styles={css({
              '.react-autosuggest__suggestion--highlighted': {
                backgroundColor: theme.alias.background_100,
              },
            })}
          />
          <SWRConfig
            value={{
              provider: () => new Map(),
            }}
          >
            <ThemeProvider theme={theme}>
              {isEnabled(GLOBAL_ALERT, configValues) && <GlobalAlert />}

              {children}
            </ThemeProvider>
          </SWRConfig>
        </>
      </LanguageProvider>
      {/* The speed insights library break in tests See */}
      {/* https://github.com/vercel/speed-insights/issues/56 for more information. */}
      {!!process.env.NEXT_PUBLIC_VERCEL_ENV && (
        <SpeedInsights sampleRate={0.05} />
      )}
    </OtovoConfigContext.Provider>
  );
};

export default NextPageRoot;
