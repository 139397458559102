import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'Button';

export default defineMessages({
  defaultChatLinkLabel: {
    id: `${I18N_NAMESPACE}.defaultChatLinkLabel`,
    defaultMessage: 'the chat',
  },
});
