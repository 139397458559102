import { OtovoConfigContext } from '../../hooks/configContext';

function injectConfig(WrappedComponent) {
  function getDisplayName(Component) {
    return Component.displayName || Component.name || 'Component';
  }
  function InjectConfig(props) {
    return (
      <OtovoConfigContext.Consumer>
        {(config) => {
          return <WrappedComponent {...props} config={config} />;
        }}
      </OtovoConfigContext.Consumer>
    );
  }

  InjectConfig.displayName = `InjectConfig(${getDisplayName(
    WrappedComponent,
  )})`;
  return InjectConfig;
}

export default injectConfig;
