/* eslint-disable no-console */

import { Children } from 'react';
import { debounce } from 'debounce';
import { IntlProvider, ReactIntlError } from 'react-intl';
import { Otovo$ConfigType } from '../types/otovoweb';

type Props = Otovo$ConfigType & {
  children: React.ReactNode;
};

let errors = [];
const debouncedLog = debounce(() => {
  if (errors.length && console.groupCollapsed) {
    console.groupCollapsed(`[WARN] MISSING ${errors.length} TRANSLATIONS`);
    errors.forEach((error) => {
      console.warn(error);
    });
    console.groupEnd();
  }

  errors = [];
}, 400);

function handleError(error) {
  if (error instanceof ReactIntlError) {
    if (error.code === 'MISSING_TRANSLATION') {
      errors.push(error);
    } else {
      console.warn(error);
    }
  }

  debouncedLog();
}

const LanguageProvider = ({ config, children }: Props) => {
  const messages = config.MESSAGES;
  const { locale } = config.BU_CONFIG;
  return (
    <IntlProvider locale={locale} messages={messages} onError={handleError}>
      {Children.only(children)}
    </IntlProvider>
  );
};

export default LanguageProvider;
