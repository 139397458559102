import AnimationWrapper from './AnimationWrapper';

type Props = {
  children: React.ReactNode;
  isDismissed: boolean;
  onDismiss?: () => void;
  type: 'error' | 'success' | 'cta';
};

const NotificationBanner = ({
  children,
  onDismiss,
  isDismissed,
  type,
}: Props) => {
  if (isDismissed) {
    return null;
  }
  return (
    <AnimationWrapper type={type}>
      <div className="mx-auto flex w-full max-w-7xl flex-col content-between items-center px-6 md:flex-row">
        {onDismiss && (
          <button
            type="button"
            className="absolute bottom-0 right-0 top-0 border-none bg-transparent text-current-color"
            onClick={onDismiss}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 21 21"
              width="32px"
              height="32px"
              fill="none"
              color="currentColor"
            >
              <svg>
                <circle cx="10.5" cy="10.5" r="10.5" fill="inherit" />
                <path
                  fill="currentColor"
                  d="M6.186 13.985l7.757-7.757 1.12 1.12-7.756 7.757z"
                />
                <path
                  fill="currentColor"
                  d="M13.943 15.106L6.186 7.35l1.12-1.122 7.758 7.757z"
                />
              </svg>
            </svg>
          </button>
        )}
        {children}
      </div>
    </AnimationWrapper>
  );
};

NotificationBanner.defaultProps = {
  onDismiss: undefined,
  type: undefined,
};
export default NotificationBanner;
