import Script from 'next/script';

const TrustpilotScriptLoader = () => {
  return (
    <Script
      type="text/javascript"
      src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy="lazyOnload"
      async
    />
  );
};

export default TrustpilotScriptLoader;
