import { keyframes } from '@emotion/react';

type KeyframeShape = Record<
  string,
  {
    background?: string;
    color?: string;
    transform?: string;
    opacity?: number;
  }
>;

/**
 * addTransform
 *
 * Utility function that add CSS:transform property to the following keyframes:
 * 0% – hide content visually by setting translateY(-100%)
 * 22% – reveal content by setting translateY(0)
 *
 * This works because you can declare keyframe several times with different values.
 * In the case of duplications, the last one will be respected.
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes#When_a_keyframe_is_defined_multiple_times
 *
 * This was done to avoid duplicating the same transform logic in ./animations.js
 */

function addTransform(keyframeArray: KeyframeShape[]): KeyframeShape[] {
  keyframeArray.push({
    '0%': {
      transform: 'translateY(-100%)',
      opacity: 1, // to support animation delay, opacity is set to 0 in <AnimationWrapper />. This restores it
    },
  });
  keyframeArray.push({
    '22%': {
      transform: 'translateY(0)',
    },
  });
  keyframeArray.push({
    '100%': {
      opacity: 1, // Keep opacity=1 after the animation finishes
    },
  });
  return keyframeArray;
}

export function createSlideInDownAnimationString(
  keyFrameArray: KeyframeShape[],
  animationProperties: string,
): string {
  return `${keyframes(addTransform(keyFrameArray))} ${animationProperties}`;
}

export function createKeyframeAnimation(
  keyFrameArray: Array<KeyframeShape>,
  animationProperties: string,
): string {
  return `${keyframes(...keyFrameArray)} ${animationProperties}`;
}
