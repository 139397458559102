import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'ErrorBoundary';

export default defineMessages({
  defaultErrorTitle: {
    id: `${I18N_NAMESPACE}.defaultErrorTitle`,
    defaultMessage: 'Oy! It went wrong here...',
  },

  defaultErrorDescription: {
    id: `${I18N_NAMESPACE}.defaultErrorDescription`,
    defaultMessage:
      'Unfortunately, an error occurred. We are working on it and will fix it as soon as possible. Try loading the page again or wait for next sunrise. In the meantime, you can contact us at {email}.',
  },
});
