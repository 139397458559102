import theme from '../rainbow/theme';
import { RainbowTheme } from '../rainbow/types';

const defaultTheme = {
  /** Default theme is defined inside rainbow */
  ...theme,
  gradients: {
    ...theme.gradients,
    variant1: `linear-gradient(101deg, #1e1e4a, ${theme.colors.lilac_100})`,
    variant2: 'linear-gradient(190deg, #FFFFFF 1.8%, #F1F3FF 135%);', // secondary button
    variant3: `linear-gradient(to right, #3156c4, #1e40b4)`, // primary button
  },
  alias: {
    radii: {
      radius_100: theme.radii[2], // 4px
      radius_200: theme.radii[3], // 8px
      radius_300: theme.radii.pill, // 9999px
    },
    background_100: theme.colors.lilac_1, // #FAFAFF
    background_200: theme.colors.lilac_5, // #F5F6FF
    background_300: theme.colors.lilac_10, // #EDEFFF
    background_400: theme.colors.grey_90, // #1b2438
    background: {
      contrast_100: theme.colors.blue_60, // #1E40B4
      contrast_200: theme.colors.blue_50, // #3156C4
    },
    text_100: theme.colors.blue_60, // #1E40B4 btn:secondary:tertiary text
    text_200: theme.colors.lilac_60, // #7A7CA3
    text_300: '#ffffff', // stickyheader - left content text color, btn:primary text
    border_100: theme.colors.blue_60, // #1E40B4
    border: {
      contrast_100: `${theme.colors.lilac_60}30`, // #7A7CA330
      contrast_200: `${theme.colors.blue_30}`, // #A0BAF6
    },
    states: {
      success: theme.colors.green_40, // #11EFA1 have to be Hex because of hexToRGB()
      neutral: theme.colors.lilac_10, // #EDEFFF
      warning: theme.colors.peach_50, // #FE8F65
      danger: theme.colors.red_50, // #E23B3E
    },
  },
};

/**
 * Resolve theme for an affiliate
 */
export function resolveAffiliateTheme(utmSources: string[]): RainbowTheme {
  switch (true) {
    case utmSources.includes('yippie-de'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: '#f8f7fd',
          background_200: '#ddd5f6',
          background_300: '#eeeafa',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#562fd1',
            contrast_200: '#ed6b06',
          },
          text_100: '#4526a7',
          text_200: '#261782',
          text_300: '#ffffff',
          border_100: '#221354',
          border: {
            contrast_100: '#a2a2a2',
            contrast_200: '#5e5851',
          },
          states: {
            success: '#ed6b06',
            neutral: '#ddd5f6',
            warning: '#562fd1',
            danger: '#db4223',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(45deg, #4526a7, #4526a7)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`,
          variant3: 'linear-gradient(to right, #211c36, #211c36)',
        },
      };
    case utmSources.includes('segugio'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: '#f5f8fa',
          background_200: '#ccdde2',
          background_300: '#eaf1f4',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#4b8426',
            contrast_200: '#ed6b06',
          },
          text_100: '#00576f',
          text_200: '#261782',
          text_300: '#ffffff',
          border_100: '#00576f',
          border: {
            contrast_100: '#a2a2a2',
            contrast_200: '#5e5851',
          },
          states: {
            success: '#ed6b06',
            neutral: '#ddd5f6',
            warning: '#562fd1',
            danger: '#db4223',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(45deg, #00576f, #00576f)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`,
          variant3: 'linear-gradient(to right, #61ac31, #61ac31)',
        },
      };
    case utmSources.includes('bricoman'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: '#fcf2eb',
          background_200: '#fadfca',
          background_300: '#ffdbbf',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#f29750',
            contrast_200: '#ed6b06',
          },
          text_100: '#ed6b06',
          text_200: '#261782',
          text_300: '#ffffff',
          border_100: '#ed6b06',
          border: {
            contrast_100: '#a2a2a2',
            contrast_200: '#5e5851',
          },
          states: {
            success: '#ed6b06', // have to be Hex because of hexToRGB()
            neutral: '#fcf2eb',
            warning: '#fc9a4e',
            danger: '#db4223',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(0deg, #120949, #120949)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #ed6b06, #ed6b06)',
        },
      };
    case utmSources.includes('monter'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: '#F6F5F4',
          background_200: '#E5E3E2',
          background_300: '#F6F1E0',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#F7E8B7',
            contrast_200: '#EE3831',
          },
          text_100: '#101820',
          text_200: '#261782',
          text_300: '#EE3831',
          border_100: '#101820',
          border: {
            contrast_100: '#EE3831',
            contrast_200: '#5e5851',
          },
          states: {
            success: '#F8E08E',
            neutral: '#fcf2eb',
            warning: '#fc9a4e',
            danger: '#db4223',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(0deg, #101820, #101820)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #F8E08E, #F8E08E)',
        },
      };
    case utmSources.includes('fastweb'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: '#fdfbf7',
          background_200: '#fff5cc',
          background_300: '#feecad',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#f2a800',
            contrast_200: '#dcbc72',
          },
          text_100: '#f2a800',
          text_200: '#5b52cf',
          text_300: '#ffffff',
          border_100: '#f2a800',
          border: {
            contrast_100: '#c1c7cd',
            contrast_200: '#5b5a53',
          },
          states: {
            success: '#fddc00', // have to be Hex because of hexToRGB()
            neutral: '#fff8cc',
            warning: '#ffc107',
            danger: '#ff4b3d',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #000, #000)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #FDDC00, #e4c600)',
        },
      };
    case utmSources.includes('leroymerlin'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '4px',
            radius_300: '20px',
          },
          background_100: 'hsl(79,88%, 99%)', // muted
          background_200: 'hsl(79,88%, 97%)', // +2
          background_300: 'hsl(79,88%, 89%)', // +1
          background_400: theme.colors.grey_90,
          text_100: '#46a610',
          text_200: '#FD0808',
          text_300: '#fff',
          border_100: '#188803',
          border: {
            contrast_100: '#b3b3b3',
            contrast_200: '#c5e39e',
          },
          background: {
            contrast_100: '#188803',
            contrast_200: '#46a610',
          },
          states: {
            success: '#46a610',
            neutral: '#0b96cc',
            warning: '#ea7315',
            danger: '#ea302d',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #78be20, #78be20)',
          variant2: `linear-gradient(0deg, #fff 19.85%, #fff 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #46a610, #46a610)',
        },
      };
    case utmSources.includes('beem'):
      return {
        /** Default theme is defined inside rainbow */
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(101deg, #00402D, #00402D)`,
          variant2: 'linear-gradient(190deg, #FFFFFF 1.8%, #F1F3FF 135%);',
          variant3: `linear-gradient(to right, #336657, #336657)`,
        },
        alias: {
          radii: {
            radius_100: '20px',
            radius_200: theme.radii[3],
            radius_300: theme.radii.pill,
          },
          background_100: '#F6F7F9',
          background_200: '#f1f2f4',
          background_300: '#ecedef',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#477568',
            contrast_200: '00402D',
          },
          text_100: '#336657',
          text_200: theme.colors.lilac_60,
          text_300: '#ffffff',
          border_100: '#336657',
          border: {
            contrast_100: `#00402D`,
            contrast_200: `#668c81`,
          },
          states: {
            success: theme.colors.green_50,
            neutral: theme.colors.lilac_10,
            warning: theme.colors.peach_50,
            danger: theme.colors.red_50,
          },
        },
      };
    case utmSources.includes('media_markt_saturn'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '8px',
          },
          /** Semantic aliases */
          background_100: '#efefef',
          background_200: '#f4f4f4',
          background_300: '#ffffff',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#494745',
            contrast_200: '#ffffff',
          },
          text_100: '#292733',
          text_200: '#292733',
          text_300: '#ffffff',
          border_100: '#e30613',
          border: {
            contrast_100: '#f4f4f4',
            contrast_200: '#494745',
          },
          states: {
            success: '#009fe3', // have to be Hex because of hexToRGB()
            neutral: '#f4f4f4',
            warning: '#e30613',
            danger: '#db4223',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(to right, #e30613, #e30613)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #000000, #000000)',
        },
      };
    case utmSources.includes('fixo-pt'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '4px',
            radius_300: '4px',
          },
          background_100: '#f7f8fa',
          background_200: '#edf0f5',
          background_300: '#dfe3eb',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#3d4a66',
            contrast_200: '#ffffff',
          },
          text_100: '#c91435',
          text_200: '#2e384d',
          text_300: '#ffffff',
          border_100: '#E4002B',
          border: {
            contrast_100: '#edf0f5',
            contrast_200: '#2e384d',
          },
          states: {
            success: '#61aef2',
            neutral: '#f4f4f4',
            warning: '#e30613',
            danger: '#db4223',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(to right, #E4002B, #E4002B)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #2e384d, #2e384d)',
        },
      };
    case utmSources.includes('luzboa-pt'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '4px',
            radius_300: '28px',
          },
          background_100: 'rgb(184 154 208 / 20%)',
          background_200: 'rgb(184 154 208 / 15%)',
          background_300: '#ffffff',
          background_400: '#fa7e5f',
          background: {
            contrast_100: '#f59b80',
            contrast_200: '#f59b80',
          },
          text_100: '#fa7e5f',
          text_200: '#f59b80',
          text_300: '#ffffff',
          border_100: '#fa7e5f',
          border: {
            contrast_100: '#edf0f5',
            contrast_200: '#fa7e5f',
          },
          states: {
            success: '#fa7e5f',
            neutral: theme.colors.lilac_10,
            warning: theme.colors.peach_50,
            danger: theme.colors.red_50,
          },
        },
        gradients: {
          ...theme.gradients,
          variant1:
            'linear-gradient(333deg, #642a93 23%, #572a93 47%, #392a93 79%, #8f2a93 100%)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(0deg,#fa7e5f 0%,#fa7e5f 100%)',
        },
      };
    case utmSources.includes('leroymerlin-pt'):
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '4px',
            radius_300: '20px',
          },
          background_100: 'hsl(79,88%, 99%)', // muted
          background_200: 'hsl(79,88%, 97%)', // +2
          background_300: 'hsl(79,88%, 89%)', // +1
          background_400: '#46a610',
          text_100: '#46a610',
          text_200: '#FD0808',
          text_300: '#fff',
          border_100: '#188803',
          border: {
            contrast_100: '#b3b3b3',
            contrast_200: '#c5e39e',
          },
          background: {
            contrast_100: '#188803',
            contrast_200: '#46a610',
          },
          states: {
            success: '#46a610',
            neutral: '#0b96cc',
            warning: '#ea7315',
            danger: '#ea302d',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #78be20, #78be20)',
          variant2: `linear-gradient(0deg, #fff 19.85%, #fff 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #46a610, #46a610)',
        },
      };
    default:
      return defaultTheme;
  }
}

/**
 * Resolve theme for a business unit
 */
export function resolveBusinessUnitTheme(buSlug: string): RainbowTheme {
  switch (buSlug) {
    case 'meyerburger-at':
    case 'meyerburger-ch':
    case 'meyerburger-de':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '0px',
          },
          /** Semantic aliases */
          background_100: '#fafafa',
          background_200: '#f8f8f8',
          background_300: '#f0f0f0',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: 'hsl(347deg, 100%, 35%)',
            contrast_200: '#e30031',
          },
          text_100: '#000',
          text_200: '#a0a0a0',
          text_300: '#ffffff',
          border_100: '#000',
          border: {
            contrast_100: '#f0f0f0',
            contrast_200: '#ededed',
          },
          states: {
            success: '#55DC8C', // have to be Hex because of hexToRGB()
            neutral: '#D0F1DD',
            warning: '#FFC107',
            danger: '#A30013',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #000, #000)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #e30031, #e30031)',
        },
      };
    case 'telinet-se':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: '#F7FCFD',
          background_200: '#EFF9FA',
          background_300: '#E8F6F7',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#2DC0D0',
            contrast_200: '#7FD3DC',
          },
          text_100: '#2DC0D0',
          text_200: '#8ACED6',
          text_300: '#ffffff',
          border_100: '#2DC0D0',
          border: {
            contrast_100: '#D9F0F2',
            contrast_200: '#C2E7EA',
          },
          states: {
            success: '#55DC8C', // have to be Hex because of hexToRGB()
            neutral: '#D0F1DD',
            warning: '#FFC107',
            danger: '#A30013',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #0A416E, #072F4F)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #55DC8C, #26BA63)',
        },
      };
    case 'grittienergia-it':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: '#fef9ef',
          background_200: '#fdf3de',
          background_300: '#fbe7bd',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#f8d68c',
            contrast_200: '#f7d07c',
          },
          text_100: '#005b72',
          text_200: '#000000',
          text_300: '#004353',
          border_100: '#005b72',
          border: {
            contrast_100: '#ccdee3',
            contrast_200: '#669daa',
          },
          states: {
            success: '#F5C45B', // have to be Hex because of hexToRGB()
            neutral: '#fef9ef',
            warning: '#FFC107',
            danger: '#A30013',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #004353, #005b72)',
          variant2: `linear-gradient(0deg, #fdf3de 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #F7D07C, #F5C45B)',
        },
      };
    case 'vivi-it':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          /** Semantic aliases */
          background_100: 'hsl(296, 24%, 98%)',
          background_200: 'hsl(296, 28%, 96%)',
          background_300: 'hsl(296, 32%, 94%)',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: 'hsl(284, 57%, 39%)',
            contrast_200: 'hsl(301, 86%, 32%)',
          },
          text_100: 'hsl(285, 42%, 39%)',
          text_200: 'hsl(285, 37%, 52%)',
          text_300: '#ffffff',
          border_100: 'hsl(301, 86%, 32%)',
          border: {
            contrast_100: 'hsl(303, 34%, 88%)',
            contrast_200: 'hsl(300, 26%, 78%)',
          },
          states: {
            success: '#4BC485', // have to be Hex because of hexToRGB()
            neutral: 'hsl(303, 41%, 90%)',
            warning: 'hsl(33, 100%, 50%)',
            danger: 'hsl(336, 100%, 50%)',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(101deg, hsl(280, 38%, 39%), hsl(279, 38%, 22%))`,
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: `linear-gradient(to right, hsl(202, 100%, 38%), hsl(297, 65%, 34%))`,
        },
      };
    case 'santander-br':
      return {
        ...theme,
        /** Semantic aliases */
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: 'hsla(113, 34%, 98%, 1)',
          background_200: 'hsla(113, 32%, 96%, 1)',
          background_300: 'hsla(113, 30%, 94%, 1)',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: 'hsla(113, 34%, 58%, 1)',
            contrast_200: 'hsla(113, 39%, 53%, 1)',
          },
          text_100: 'hsla(113, 32%, 44%, 1)',
          text_200: 'hsla(113, 15%, 55%, 1)',
          text_300: '#ffffff',
          border_100: 'hsla(113, 32%, 44%, 1)',
          border: {
            contrast_100: 'hsla(113, 29%, 86%, 1)',
            contrast_200: 'hsla(112, 29%, 78%, 1)',
          },
          states: {
            success: '#64BA69', // have to be Hex
            neutral: 'hsla(112, 29%, 90%, 1)',
            warning: 'hsl(45, 100%, 60%)',
            danger: 'hsl(0, 100%, 40%)',
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(to right, hsla(111, 29%, 45%, 1), hsla(111, 29%, 31%, 1))`,
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: `linear-gradient(to right, hsla(113, 34%, 58%, 1), hsla(111, 29%, 45%, 1))`,
        },
      };
    case 'alterna-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(90deg, #06114A 0%, #061164 100%)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #FFB422 0%, #FFAA05 100%)',
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: '#F9FAFF',
          background_200: '#F3F4FF',
          background_300: '#EBEEFF',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#F09E00',
            contrast_200: '#FFB422',
          },
          text_100: '#06114A',
          text_200: '#4755A0',
          text_300: '#ffffff',
          border_100: '#4755A0',
          border: {
            contrast_100: '#D1D7F8',
            contrast_200: '#A7B3FF',
          },
          states: {
            success: '#B2D329', // have to be Hex because of hexToRGB()
            neutral: '#FFECC7',
            warning: '#FFB422',
            danger: '#D80E36',
          },
        },
      };
    case 'butik-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(90deg, #06114A 0%, #061164 100%)',
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #677BE7 0%, #5369e4 100%)',
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: '#F9FAFF',
          background_200: '#F3F4FF',
          background_300: '#EBEEFF',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#677BE7',
            contrast_200: '#FFB422',
          },
          text_100: '#06114A',
          text_200: '#4755A0',
          text_300: '#ffffff',
          border_100: '#677BE7',
          border: {
            contrast_100: '#D1D7F8',
            contrast_200: '#A7B3FF',
          },
          states: {
            success: '#677BE7', // have to be Hex
            neutral: '#FFECC7',
            warning: '#FFB422',
            danger: '#D80E36',
          },
        },
      };
    case 'imaginaenergia-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(90deg, #F26522 0%, #F26522 100%)', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #e2432b 20%, #f5a036 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: '#fffbfa', // muted
          background_200: '#fff3f0', // +2
          background_300: '#ffebe6', // +1
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#e2432b', // button bg
            contrast_200: '#FFB422',
          },
          text_100: '#06114A',
          text_200: '#4755A0',
          text_300: '#ffffff',
          border_100: '#E14F29', // var(--naranja)
          border: {
            contrast_100: '#ffebe6',
            contrast_200: '#FFB422',
          },
          states: {
            success: '#ff420c', // have to be Hex because of hexToRGB()
            neutral: '#ffdd99',
            warning: '#fdc103', // from logo - OK?
            danger: '#ff1d0c', // from logo - OK?
          },
        },
      };
    case 'lucera-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1:
            // hsl(158, 100%, 98%) // base
            // 'linear-gradient(90deg, hsl(160, 98%, 37%) 0%, hsl(160, 98%, 37%) 100%)', // stickyheader
            'linear-gradient(90deg, #001a4d 0%, #001a4d 100%)', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3: 'linear-gradient(90deg, #00FFAA 0%, #00FFAA 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: 'hsl(160, 98%, 98%)', // muted
          background_200: 'hsl(160, 98%, 95%)', // +2
          background_300: 'hsl(160, 80%, 90%)', // +1
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#03ee9f', // button bg
            contrast_200: '#00FFAA',
          },
          text_100: '#001a4d',
          text_200: 'hsl(220 100%  15% / 0.8)',
          text_300: '#001a4d',
          border_100: 'hsl(160, 92%, 40%)',
          border: {
            contrast_100: 'hsl(160, 60%, 90%)',
            contrast_200: 'hsl(160, 60%, 60%)',
          },
          states: {
            success: '#03ee9f', // have to be Hex because of hexToRGB()
            neutral: '#E3E8E6',
            warning: '#fdc103',
            danger: '#da1e28',
          },
        },
      };
    case 'masmovil-es':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1:
            'linear-gradient(90deg, hsl(53, 0%, 20%) 0%, hsl(53, 0%, 0%) 100%)', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`, // secondary button
          variant3:
            'linear-gradient(90deg, hsl(97, 100%, 32%) 0%, hsl(97, 100%, 32%) 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '6px',
            radius_300: '8px',
          },
          background_100: 'hsl(53, 98%, 95%)', // muted
          background_200: 'hsl(53, 98%, 91%)', // +2
          background_300: 'hsl(53, 100%, 50%)', // +1
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: 'hsl(97, 100%, 35%)', // button bg
            contrast_200: 'hsl(97, 100%, 38%)',
          },
          text_100: '#333333', // btn group text
          text_200: '#444a4f',
          text_300: '#fff', // stickyheader - left content text color
          border_100: '#000', // icon colors
          border: {
            contrast_100: 'hsl(53 0% 0% / 0.1)', // btn secondary border, borders, unselected in btn group
            contrast_200: 'hsl(0 0% 40%)',
          },
          states: {
            success: '#ffe200', // have to be Hex because of hexToRGB()
            neutral: 'hsl(53, 45%, 85%)',
            warning: '#fdc103',
            danger: '#ee001d',
          },
        },
      };
    case 'enerviva-it':
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: `linear-gradient(101deg, #104035, #034638)`,
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`,
          variant3: '#50c800',
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '6px',
            radius_300: '999px',
          },
          background_100: '#FAFAFF',
          background_200: '#F5F6FF',
          background_300: '#EDEFFF',
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#0bbd58',
            contrast_200: '#50c800',
          },
          text_100: '#0bbd58',
          text_200: '#7A7CA3',
          text_300: '#ffffff',
          border_100: '#0bbd58',
          border: {
            contrast_100: '#7A7CA330',
            contrast_200: '#A0BAF6',
          },
          states: {
            success: '#11EFA1',
            neutral: '#E3E8E6',
            warning: '#fdc103',
            danger: '#da1e28',
          },
        },
      };
    case 'oktavia-it':
      // Main brand colors: #8565ff and #bdf347
      return {
        ...theme,
        gradients: {
          ...theme.gradients,
          variant1: '#8565ff', // stickyheader
          variant2: `linear-gradient(0deg, #FFFFFF 19.85%, #FFF 118.93%)`,
          variant3: 'linear-gradient(90deg, #CC33FF 0%, #6633FF 100%)', // button
        },
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '8px',
            radius_300: '999px',
          },
          background_100: 'hsl(79,88%, 99%)', // muted
          background_200: 'hsl(79,88%, 97%)', // +2
          background_300: 'hsl(79, 88%, 62%)', // +1
          background_400: theme.colors.grey_90,
          background: {
            contrast_100: '#CC33FF', // button bg
            contrast_200: '#ffb422',
          },
          text_100: '#06114a',
          text_200: '#4755a0',
          text_300: '#ffffff',
          border_100: '#8565ff',
          border: {
            contrast_100: 'hsl(255, 100%, 92%)',
            contrast_200: 'hsl(255, 100%, 85%)',
          },
          states: {
            success: '#8565ff',
            neutral: '#ffecc7',
            warning: '#ffb422',
            danger: '#d80e36',
          },
        },
      };
    case 'cardinall-pl':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '4px',
            radius_300: '20px',
          },
          background_100: '#e6e6e6',
          background_200: '#e6f5ed',
          background_300: '#b3e2c8',
          background_400: theme.colors.grey_90,
          text_100: '#66B658',
          text_200: '#5ca44f',
          text_300: '#fff',
          border_100: '#009F48',
          border: {
            contrast_100: '#b3b3b3',
            contrast_200: '#009F48',
          },
          background: {
            contrast_100: '#009F48',
            contrast_200: '#242A56',
          },
          states: {
            success: '#66B658',
            neutral: theme.colors.lilac_10, // #EDEFFF
            warning: theme.colors.peach_50, // #FE8F65
            danger: theme.colors.red_50, // #E23B3E
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #242A56, #242A56)',
          variant2: `linear-gradient(0deg, #fff 19.85%, #fff 118.93%)`, // secondary button
          variant3: 'linear-gradient(to right, #009F48, #009F48)',
        },
      };
    case 'facile-it':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: theme.radii[0],
            radius_200: '4px',
            radius_300: '8px',
          },
          background_100: 'hsl(24, 100%, 99%)',
          background_200: 'hsl(24, 100%, 97%)',
          background_300: 'hsl(24, 100%, 94%)',
          background_400: theme.colors.grey_90,
          text_100: '#FF6600',
          text_200: 'hsl(0, 0%, 70%)',
          text_300: '#fff',
          border_100: '#FF6600', // icons
          border: {
            contrast_100: 'hsl(24, 100%, 94%)', // border
            contrast_200: '#FF6600',
          },
          background: {
            contrast_100: 'hsl(90, 61%, 40%)',
            contrast_200: '#242A56',
          },
          states: {
            success: '#66B658',
            neutral: theme.colors.lilac_10, // #EDEFFF
            warning: '#FF6600', // #FE8F65
            danger: theme.colors.red_50, // #E23B3E
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #FF6600, #FF6600)',
          variant2: `linear-gradient(190deg, #FFFFFF 1.8%, hsl(24, 100%, 97%) 135%);`, // secondary button
          variant3: 'linear-gradient(to right, #71B62C, #71B62C)',
        },
      };
    case 'mgc-it':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: '4px',
            radius_200: '4px',
            radius_300: '8px',
          },
          background_100: '#f8fcfb',
          background_200: '#eef8f5',
          background_300: '#cceae3',
          background_400: theme.colors.grey_90,
          text_100: '#009673',
          text_200: 'hsl(0, 0%, 70%)',
          text_300: '#fff',
          border_100: '#009673', // icons
          border: {
            contrast_100: '#cceae3', // border
            contrast_200: '#009673',
          },
          background: {
            contrast_100: '#4db69d',
            contrast_200: '#242A56',
          },
          states: {
            success: '#009673',
            neutral: theme.colors.lilac_10, // #EDEFFF
            warning: '#009673', // #FE8F65
            danger: theme.colors.red_50, // #E23B3E
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #1B2438, #1B2438)',
          variant2: `linear-gradient(190deg, #FFFFFF 1.8%, #f8fcfb 135%);`,
          variant3: 'linear-gradient(to right, #009673, #009673)',
        },
      };
    case 'societaenergiaitalia-it':
      return {
        ...theme,
        alias: {
          radii: {
            radius_100: theme.radii[2],
            radius_200: theme.radii[3],
            radius_300: theme.radii.pill,
          },
          background_100: '#F9F9F9',
          background_200: '#fef5e6',
          background_300: '#fdeacc',
          background_400: theme.colors.grey_90,
          text_100: '#1B2438',
          text_200: 'hsl(0, 0%, 70%)',
          text_300: '#fff',
          border_100: '#f49600', // icons
          border: {
            contrast_100: '#fdeacc', // border
            contrast_200: '#f49600',
          },
          background: {
            contrast_100: '#f7b64d',
            contrast_200: '#fdeacc',
          },
          states: {
            success: '#f49600',
            neutral: theme.colors.lilac_10, // #EDEFFF
            warning: '#f49600', // #FE8F65
            danger: theme.colors.red_50, // #E23B3E
          },
        },
        gradients: {
          ...theme.gradients,
          variant1: 'linear-gradient(101deg, #1B2438, #1B2438)',
          variant2: `linear-gradient(190deg, #FFFFFF 1.8%, #F9F9F9 135%);`,
          variant3: 'linear-gradient(to right, #F49600, #F49600)',
        },
      };
    default:
      return defaultTheme;
  }
}
