const IS_MAIL_TO_LINK = /^mailto:/;
const IS_TEL_LINK = /^tel:/;
const IS_LINK_WITH_PROTOCOL = /:\/\//;

type Args = {
  as?: any;
  href?: string;
  target?: string;
};

export const linkTags = Object.freeze({
  BUTTON: 'button',
  ANCHOR: 'anchor',
});

export function getLinkTag({
  as = '',
  target = '',
  href = '',
}: Args): string | null {
  const forceAnchorTag =
    target === '_blank' ||
    IS_MAIL_TO_LINK.test(href) ||
    IS_TEL_LINK.test(href) ||
    IS_LINK_WITH_PROTOCOL.test(href);

  // Some things are just plain anchors
  if (forceAnchorTag) {
    return linkTags.ANCHOR;
  }

  if (as) {
    return as;
  }

  if (!href) {
    // Weird state, we have neither "as" nor "href". Fallback to a basic "a".
    return linkTags.ANCHOR;
  }

  // Use a Next JS internal app transition
  return null;
}
