import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'GlobalAlert';

export default defineMessages({
  content: {
    id: `${I18N_NAMESPACE}.content`,
    defaultMessage:
      "We're doing some upgrades. Please come back later if things don't work.",
  },
});
