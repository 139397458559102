import { FormattedMessage as T } from 'react-intl';

import { openChat } from '../../lib/chatUtils';

import RainbowLink from '../RainbowLink/RainbowLink';
import m from './messages';

type Props = { children: React.ReactNode; className?: string };

const OpenChat = ({ children, className }: Props) => (
  <RainbowLink as="button" className={className} onClick={() => openChat()}>
    {children || <T {...m.defaultChatLinkLabel} />}
  </RainbowLink>
);

OpenChat.defaultProps = {
  children: null,
};

export default OpenChat;
