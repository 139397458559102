import { useGlobalConfig } from '../hooks/configContext';
import RainbowLink from './RainbowLink/RainbowLink';

type Props = {
  email?: string;
  emailLabel?: React.ReactNode;
  className?: string;
};

const OtovoEmailLink = ({ email, emailLabel, className }: Props) => {
  const { BU_CONFIG } = useGlobalConfig();
  const sendTo = email || BU_CONFIG.contact_email;
  return (
    <RainbowLink
      className={`whitespace-nowrap ${className}`}
      href={`mailto:${sendTo}`}
    >
      {emailLabel || sendTo}
    </RainbowLink>
  );
};

export default OtovoEmailLink;
