type ModernHouseWithSolarPanelsProps = {
  variant?: 'night' | 'day';
};

function ModernHouseWithSolarPanels({
  variant = 'day',
}: ModernHouseWithSolarPanelsProps) {
  return (
    <svg
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000.01 643.25"
      width="100%"
      height="100%"
    >
      <path
        fill="#5378dd"
        d="M777.42 369.55L773.67 369.55 765.81 369.55 765.81 297.49 777.42 297.49 777.42 219.75 777.42 61.26 513.52 219.75 513.52 643.25 698.94 643.25 777.42 643.25 873.17 643.25 873.17 369.55 777.42 369.55z"
      />
      <path
        fill="#fe8f65"
        d="M777.42 61.26L434.7 61.26 170.8 219.75 513.53 219.75 777.42 61.26z"
      />
      <path
        id="bush_back"
        d="M16.84 643.25h161.92v-75.89s-13.85-.48-34.15 8.5c-17.55 7.76-25.5 17.83-31.3 23.54-6.24 6.13-14 14.68-37.61 8.9-45.69-11.15-58.86 34.95-58.86 34.95z"
        fill="#5378dd"
      />
      {variant === 'night' && (
        <path
          id="moon"
          d="M238.11 61.26c-20.57 0-37.24-16.68-37.24-37.24 0-9.13 3.29-17.49 8.74-23.96C188 1.14 170.81 19 170.81 40.88s18.3 40.87 40.87 40.87c15.67 0 29.27-8.82 36.13-21.76-3.09.83-6.34 1.28-9.69 1.28z"
          fill="#fe8f65"
        />
      )}
      {variant === 'day' && (
        <circle id="sun" cx={211.67} cy={40.87} r={40.87} fill="#fe8f65" />
      )}
      <path fill="#e6e9ff" d="M170.8 219.75H513.53V643.24H170.8z" />
      <path fill="#1a1a47" d="M558.39 297.49H765.81V369.55H558.39z" />
      <path
        fill="#1a1a47"
        d="M558.39 476.51H670.9399999999999V571.96H558.39z"
      />
      <path
        fill="#1a1a47"
        d="M715.78 476.51H828.3299999999999V571.96H715.78z"
      />
      <path
        id="bush_front"
        d="M1000.01 643.25c-19.87-42.72-59.44-77.73-107.93-71.29-30.21 4-62.71 37.07-94.44 39.43-23.3 1.72-31-10.35-50.15-10.6-25-.31-30.71 19.05-49.2 20.91-20.48 2.06-30.54-9-51.88-5.77-23.76 3.62-37.76 27.32-37.76 27.32h391.36z"
        fill="#1e40b4"
      />
      <path
        fill="#a0baf6"
        d="M655.66 476.51H670.9399999999999V571.96H655.66z"
      />
      <path
        fill="#a0baf6"
        d="M813.05 476.51H828.3299999999999V571.96H813.05z"
      />
      <path
        id="tree"
        d="M146.01 461.15c8.45-13.24 4.56-30.83-8.69-39.27-2.9-1.85-6.11-3.16-9.47-3.86-2.09-.43-4.22-.63-6.36-.59-8.45.19-16.4-4.01-21-11.11a44.397 44.397 0 00-41.67-20c-20.09 1.8-36.36 17.07-39.44 37a44.8 44.8 0 001 18.86c2.04 7.65 0 15.81-5.37 21.62A55.825 55.825 0 00.6 510.06c3.41 23.67 23.21 38.49 45.62 46.85 13.54 5 25.15 14.25 25.15 32.85v26.1h23.49v-34.3c0-15.84 6.3-24.3 20.08-35.1 14.17-11.09 32.66-22.71 33.89-41.75.51-7.62-.98-15.23-4.34-22.09a21.846 21.846 0 011.52-21.47z"
        fill="#5378dd"
      />
      <path
        fill="#fe8f65"
        d="M226.45 491.46H306.79999999999995V643.25H226.45z"
      />
      <path fill="#a0baf6" d="M226.45 297.49H457.88V369.55H226.45z" />
      <path fill="#a0baf6" d="M389.47 491.46H457.88V563.52H389.47z" />
      <path fill="#5378dd" d="M226.45 297.49H241.73V369.55H226.45z" />
      <path
        fill="#5378dd"
        d="M374.19 491.46H389.46999999999997V563.52H374.19z"
      />
      <path fill="#1a1a47" d="M558.39 476.51H655.66V571.96H558.39z" />
      <path
        fill="#1a1a47"
        d="M524.8 73.95L442.95 73.95 338.64 136.6 420.49 136.6 524.8 73.95z"
      />
      <path
        fill="#1a1a47"
        d="M625.86 73.95L544.01 73.95 439.7 136.6 521.55 136.6 625.86 73.95z"
      />
      <path
        fill="#1a1a47"
        d="M726.92 73.95L645.07 73.95 540.76 136.6 622.61 136.6 726.92 73.95z"
      />
      <path
        fill="#1a1a47"
        d="M407.46 144.42L325.61 144.42 221.3 207.06 303.15 207.06 407.46 144.42z"
      />
      <path
        fill="#1a1a47"
        d="M508.52 144.42L426.67 144.42 322.36 207.06 404.21 207.06 508.52 144.42z"
      />
      <path
        fill="#1a1a47"
        d="M609.58 144.42L527.73 144.42 423.42 207.06 505.27 207.06 609.58 144.42z"
      />
    </svg>
  );
}

export default ModernHouseWithSolarPanels;
