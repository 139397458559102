import dynamic from 'next/dynamic';
import Head from 'next/head';

import NextPageRoot from '@otovo/shared/NextPageRoot';
import ErrorBoundary from '@otovo/shared/components/ErrorBoundary/ErrorBoundary';

import { LanguageSwitcherPathResolver } from '@otovo/shared/components/Navigation/types';
import TrustpilotScriptLoader from '@otovo/shared/components/TrustpilotScriptLoader';
import resolveTheme from '@otovo/shared/resolveTheme';
import { Otovo$Config } from '@otovo/shared/types/otovoweb';
import { Sanity$Document } from '@otovo/shared/types/sanityTypes';

const StorefrontNav = dynamic(() => import('./StorefrontNav'));

const CustomFooter = dynamic(
  () =>
    import('./SanityLandingPage/components/Footer/FranceFooter/CustomFooter'),
);
const Footer = dynamic(
  () => import('./SanityLandingPage/components/Footer/Footer'),
);

type Props = {
  children: React.ReactNode;
  config: Otovo$Config;
  hideMenu?: boolean;
  hideFooter?: boolean;
  sanityDocument?: Sanity$Document;
  languageSwitcherPathResolver?: LanguageSwitcherPathResolver;
};

const StorefrontPage = (props: Props) => {
  const resolveFooter = (market: string, hideFooter: boolean) => {
    if (hideFooter) {
      return null;
    }
    return market === 'fr' ? <CustomFooter /> : <Footer />;
  };

  const {
    children,
    config,
    hideMenu = false,
    hideFooter = false,
    sanityDocument,
    languageSwitcherPathResolver,
  } = props;

  const theme = resolveTheme(config.BU_CONFIG);

  return (
    <NextPageRoot configValues={config} theme={theme}>
      <Head>
        <link
          rel="shortcut icon"
          type="image/x-icon"
          href={config.BU_CONFIG.favicon}
          key={`favicon-${config.BU_CONFIG.company_name}`}
        />
        {config.BU_CONFIG.supported_locales.length > 1 && (
          <script
            src={`/${config.BU_CONFIG.locale}/js/routerProxy.js`}
            type="text/javascript"
          />
        )}
      </Head>
      <div className="relative flex h-screen flex-col bg-white">
        {hideMenu ? null : (
          <StorefrontNav
            sanityDocument={sanityDocument}
            languageSwitcherPathResolver={languageSwitcherPathResolver}
          />
        )}
        <ErrorBoundary name="Storefront top level error boundary">
          <main className="flex flex-[1_0_auto] flex-col">{children}</main>
        </ErrorBoundary>
        {resolveFooter(config.BU_CONFIG.market, hideFooter)}
      </div>
      <TrustpilotScriptLoader />
    </NextPageRoot>
  );
};

export default StorefrontPage;
