import { Otovo$Config } from '../../types/otovoweb';

export const isEnabled = (
  featureToggleName: keyof Otovo$Config['FEATURE_TOGGLES'],
  config: Otovo$Config,
): boolean => {
  return (
    config.FEATURE_TOGGLES[featureToggleName] &&
    !!config.FEATURE_TOGGLES[featureToggleName].isEnabled
  );
};
